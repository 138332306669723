<template>
  <Dialog v-model:visible="visible" :style="{width: '450px'}" header="Discount details" :modal="true" class="grid p-fluid">
    <div class="col-12 md:col-12">
      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="title">Title</label>
          <InputText id="title" v-model.trim="entityData.title" required="true" autofocus :class="{'p-invalid': submitted && !entityData.title}" />
          <small class="p-invalid" v-if="submitted && !entityData.title">Title is required.</small>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="strategy">Strategy</label>
          <Dropdown id="strategy" v-model="entityData.strategy" :options="strategies" option-label="label" placeholder="Select a strategy" option-value="value">
          </Dropdown>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="factor">Factor</label>
          <InputText id="factor" v-model.trim="entityData.factor" autofocus/>
        </div>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="value_type">Value type</label>
          <Dropdown id="value_type" v-model="entityData.value_type" :options="valueTypes" option-label="label" placeholder="Select a value type" option-value="value">
          </Dropdown>
        </div>
      </div>

      <div class="field">
        <label for="country" class="mb-3">Country</label>
        <Dropdown id="country" v-model="entityData.country_id" :options="countries" option-label="name" placeholder="Select a Country" option-value="id">
        </Dropdown>
      </div>

      <div class="grid">
        <div class="col-12 mb-2 lg:col-12 lg:mb-0">
          <label for="description" class="mb-3">Description</label>
          <Textarea id="description" v-model="entityData.description" required="true" rows="3" cols="20" />
        </div>
      </div>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="$emit('hideDialog')"/>
      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="save" />
    </template>
  </Dialog>
</template>

<script>

import DiscountService from "@/service/DiscountService";
import CountryService from "@/service/CountryService";

export default {
  name: "DiscountForm",
  emits: ['hideDialog','saved'],
  props: {
    entity: Object,
    dialog: Boolean
  },

  countryService: null,
  entityService: null,

  computed: {
    visible: {
      get() { return this.dialog },
      set(visible) {this.$emit('hideDialog', visible)}
    },
    entityData: {
      get() { return this.entity },
    }
  },

  mounted() {
    this.countryService.fetchAll().then(data => this.countries = data.data);
  },

  created() {
    this.countryService = new CountryService();
    this.entityService = new DiscountService();
  },

  data() {
    return {
      submitted: false,
      countries: [],
      valueTypes: [
        {
          label: 'Absolute',
          value: 'absolute'
        },
        {
          label: 'Relative',
          value: 'relative'
        },
      ],
      strategies: [
        {
          label: 'Driver even birthday',
          value: 'birth-day'
        },
      ],
    }
  },

  methods: {
    save() {
      this.submitted = true;

      if (this.entity.id) {
        this.entityService.update(this.entityData).then(response => {
          this.$toast.add({severity:'success', summary: 'Success', detail: 'Discount updated', life: 3000});
          this.$emit('saved', response);
        });
      } else {
        this.entityData.type = 'discount';
        this.entityService.create(this.entityData).then(response => {
          this.$toast.add({severity:'success', summary: 'Success', detail: 'Discount created', life: 3000});
          this.$emit('saved', response);
        });
      }
    },
  }
}
</script>

<style scoped>

</style>
